<template>
  <div>
    <!-- {{ currentUser }} -->
    <v-col
      cols="12"
      class="pa-5"
      v-if="currentUser && currentUser.sites && currentUser.sites.length > 0"
    >
      <b-form-select
        v-model="selected_site_id"
        :options="userSiteCollection"
        v-on:change="changeSite"
      >
        <template v-slot:first>
          <b-form-select-option
            value="-1"
            disabled
            v-if="selected_site_id == -1"
            >{{ $t("MEDIA_LIBRARY.please_select") }}</b-form-select-option
          >
        </template>
      </b-form-select>
    </v-col>
    <ul class="menu-nav">
      <li
        v-if="permissionCan('module.pages.menu') && moduleIsEnabled('Pages')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/page') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-edit-1"></i>
          <span class="menu-text">{{ $t("MENU.PAGE_ADMINISTARATION") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('pages.page.menu') && selected_site_id > 0"
              to="/cms/pages/pages"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-web"></i>
                  <span class="menu-text">{{ $t("MENU.PAGES") }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="
                permissionCan('pages.pageTemplate.menu') && selected_site_id > 0
              "
              to="/cms/pages/pageTemplates"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-file-2"></i>
                  <span class="menu-text">{{ $t("MENU.PAGE_TEMPLATES") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <li
        v-if="
          permissionCan('module.products.menu') && moduleIsEnabled('Products')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/products') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-list"></i>
          <span class="menu-text">{{ $t("MENU.PRODUCTS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('products.product.menu')"
              to="/products/products"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-open-box"></i>
                  <span class="menu-text">{{ $t("MENU.PRODUCTS") }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="permissionCan('products.category.menu')"
              to="/products/productCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-folder"></i>
                  <span class="menu-text">{{ $t("MENU.CATEGORIES") }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="permissionCan('products.property.menu')"
              to="/products/properties"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-confetti"></i>
                  <span class="menu-text">{{ $t("MENU.PROPERTIES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('products.price_list.menu') || 1"
              to="/products/pricelists"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-price-tag"></i>
                  <span class="menu-text">Price List</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('products.setting.menu')"
              to="/products/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="
          permissionCan('module.webshop.menu') && moduleIsEnabled('Webshop')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/webShop') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-folder-1"></i>
          <span class="menu-text">WebShop</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('products.price_list.menu') || 1"
              to="/webShop/orders"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-piggy-bank"></i>
                  <span class="menu-text">{{ $t("WEBSHOP.orders") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('products.price_list.menu') || 1"
              to="/webShop/coupons"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-gift"></i>
                  <span class="menu-text">{{ $t("COUPONS.coupons") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('products.price_list.menu') || 1"
              to="/webShop/customers"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-users"></i>
                  <span class="menu-text">{{ $t("WEBSHOP.customers") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('webshop.setting.menu')"
              to="/webShop/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.news.menu') && moduleIsEnabled('News')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/news') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-list-2"></i>
          <span class="menu-text">{{ $t("MENU.NEWS") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- News-->
            <router-link
              v-if="permissionCan('news.news.menu')"
              to="/cms/news/news"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{ $t("MENU.POSTS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF News-->
            <!-- News Category -->
            <router-link
              v-if="permissionCan('news.category.menu')"
              to="/cms/news/categories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-interface-3"></i>
                  <span class="menu-text">{{
                    $t("MENU.NEWS_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF News Category -->
            <!-- News tags -->
            <router-link
              v-if="permissionCan('news.tag.menu')"
              to="/cms/news/tags"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-tag"></i>
                  <span class="menu-text">{{ $t("MENU.TAGS") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('news.setting.menu')"
              to="/cms/news/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF News tags -->
          </ul>
        </div>
      </li>

      <router-link
        v-if="
          permissionCan('system.mediaLibrary.menu') &&
          selected_site_id > 0 &&
          moduleIsEnabled('Pages')
        "
        to="/cms/media_library"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-folder"></i>
            <span class="menu-text">{{ $t("MENU.MEDIA_LIBRARY") }}</span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="
          permissionCan('pages.menu.menu') &&
          selected_site_id > 0 &&
          moduleIsEnabled('Pages')
        "
        to="/cms/menus"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-menu-3"></i>
            <span class="menu-text">{{ $t("MENU.MENUS") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="
          (permissionCan('module.contactForms.menu') ||
            permissionCan('contactForms.contactForm.menu')) &&
          moduleIsEnabled('ContactForms')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/contactForms'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-list-1"></i>
          <span class="menu-text">{{ $t("MENU.contact_form") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('contactForms.contactFormData.menu') &&
                selected_site_id > 0
              "
              to="/cms/contactForms/contactForms"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                          <span> </span>
                        </i> -->
                  <i class="menu-icon flaticon-multimedia-2"></i>
                  <span class="menu-text">{{
                    $t("MENU.incoming_contact_form")
                  }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="
                permissionCan('contactForms.contactFormEditor.menu') &&
                selected_site_id > 0
              "
              to="/cms/contactForms/FormTemplates"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i> -->
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{
                    $t("MENU.contact_form_editor")
                  }}</span>
                </a>
              </li>
            </router-link>
            <!-- <router-link
              v-if="
                permissionCan('contactForms.contactForm.menu') &&
                selected_site_id > 0 &&
                moduleIsEnabled('ContactForms')
              "
              to="/cms/contactForms"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">

                  <i class="menu-icon flaticon-multimedia-2"></i>
                  <span class="menu-text">{{ $t("MENU.contact_form") }}</span>
                </a>
              </li>
            </router-link> -->
          </ul>
        </div>
      </li>

      <li
        v-if="
          permissionCan('module.quotations.menu') &&
          moduleIsEnabled('Quotations')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/quotations'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-hangouts-logo"></i>
          <span class="menu-text">{{ $t("MENU.QUOTATIONS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('quotations.quotation.menu') &&
                selected_site_id > 0
              "
              to="/cms/quotations/quotations"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-sheet"></i>
                  <span class="menu-text">{{ $t("MENU.quotations") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="
                permissionCan('quotations.quotationCategory.menu') &&
                selected_site_id > 0
              "
              to="/cms/quotations/quotationCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-files-and-folders"></i>
                  <span class="menu-text">{{
                    $t("MENU.QUOTATION_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="
          permissionCan('logos.category.menu') &&
          selected_site_id > 0 &&
          moduleIsEnabled('Logos')
        "
        to="/cms/logos"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-image-file"></i>
            <span class="menu-text">{{ $t("MENU.LOGOS") }}</span>
          </a>
        </li>
      </router-link>

      <!--POINT-->
      <li
        v-if="permissionCan('module.blog.menu') && moduleIsEnabled('Blog')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/blog') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-list"></i>
          <span class="menu-text">Blog</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('blog.post.menu')"
              to="/cms/blog/posts"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{ $t("MENU.POSTS") }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="permissionCan('blog.category.menu')"
              to="/cms/blog/categories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-interface-3"></i>
                  <span class="menu-text">{{
                    $t("MENU.BLOG_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('blog.tag.menu')"
              to="/cms/blog/tags"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-tag"></i>
                  <span class="menu-text">{{ $t("MENU.TAGS") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('blog.setting.menu')"
              to="/cms/blog/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="
          permissionCan('module.partners.menu') &&
          selected_site_id > 0 &&
          moduleIsEnabled('Partners')
        "
        to="/cms/partners"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-user"></i>
            <span class="menu-text">{{ $t("MENU.PARTNERS") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="
          permissionCan('module.publication.menu') &&
          moduleIsEnabled('Publications')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/publications'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-shield"></i>
          <span class="menu-text">{{ $t("MENU.PUBLICATIONS") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- Publication Post -->
            <router-link
              v-if="permissionCan('publication.post.menu')"
              to="/cms/publications/publications"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{ $t("MENU.PUBLICATION") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Publication Post-->
            <!-- Publication Category -->
            <router-link
              v-if="permissionCan('publication.category.menu')"
              to="/cms/publications/publicationCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-interface-3"></i>
                  <span class="menu-text">{{
                    $t("MENU.PUBLICATION_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Publication Category -->
            <!-- Publication Settings -->
            <router-link
              v-if="permissionCan('publication.setting.menu')"
              to="/cms/publications/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Publication Settings -->
          </ul>
        </div>
      </li>

      <router-link
        v-if="
          permissionCan('system.documentLibrary.menu') &&
          // moduleIsEnabled('Pages') &&
          selected_site_id == 0
        "
        to="/settings/internal_documents"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-folder"></i>
            <span class="menu-text">{{ $t("MENU.INTERNAL_DOCUMENTS") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="
          permissionCan('module.galleries.menu') && moduleIsEnabled('Galleries')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/banners') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-squares"></i>
          <span class="menu-text">{{ $t("MENU.GALLERIES") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('galleries.category.menu') && selected_site_id > 0
              "
              to="/cms/galleries"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-squares"></i>
                  <span class="menu-text">{{ $t("MENU.GALLERIES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('galleries.setting.menu')"
              to="/cms/galleries/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="
          (permissionCan('module.banner.menu') ||
            permissionCan('pages.banner.menu')) &&
          moduleIsEnabled('Pages')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/banners') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-speech-bubble"></i>
          <span class="menu-text">{{ $t("MENU.BANNERS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('pages.banner.menu') && selected_site_id > 0"
              to="/cms/banners/banners"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i> -->
                  <i class="menu-icon flaticon-chat-1"></i>
                  <span class="menu-text">{{ $t("MENU.banners") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="
                permissionCan('pages.bannerType.menu') && selected_site_id > 0
              "
              to="/cms/banners/bannerTypes"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i> -->
                  <i class="menu-icon flaticon-tabs"></i>
                  <span class="menu-text">{{ $t("MENU.bannerTypes") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.events.menu') && moduleIsEnabled('Events')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/event') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-presentation-1"></i>
          <span class="menu-text">{{ $t("MENU.EVENTS") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- Events-->
            <router-link
              v-if="permissionCan('events.event.menu')"
              to="/cms/event/event"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{ $t("MENU.EVENTS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Event-->
            <!-- Event Category -->
            <router-link
              v-if="permissionCan('events.category.menu')"
              to="/cms/event/categories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-interface-3"></i>
                  <span class="menu-text">{{
                    $t("MENU.EVENT_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Event Category -->
            <!-- Event tags -->
            <router-link
              v-if="permissionCan('events.tag.menu')"
              to="/cms/event/tags"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-tag"></i>
                  <span class="menu-text">{{ $t("MENU.TAGS") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('events.setting.menu')"
              to="/cms/event/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Event tags -->
          </ul>
        </div>
      </li>

      <router-link
        v-if="
          permissionCan('addresses.address.menu') &&
          selected_site_id > 0 &&
          moduleIsEnabled('Addresses')
        "
        to="/cms/addresses"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon-placeholder-3"></i>
            <span class="menu-text">{{ $t("MENU.ADDRESSES") }}</span>
          </a>
        </li>
      </router-link>

      <!-- <li
        v-if="
          permissionCan('module.institutes.menu') &&
          moduleIsEnabled('Institutes')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/intitutes') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-file-2"></i>
          <span class="menu-text">{{ $t("MENU.INSTITUTES") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('institutes.institute.menu') &&
                selected_site_id > 0
              "
              to="/cms/institutes/institutes"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-file-2"></i>
                  <span class="menu-text">{{ $t("MENU.INSTITUTES") }}</span>
                </a>
              </li>
            </router-link>
            
          </ul>
        </div>
      </li> -->

      <router-link
        v-if="
          selected_site_id > 0 &&
          moduleIsEnabled('Institutes') &&
          permissionCan('institutes.institute.menu')
        "
        to="/cms/institutes/institutes"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-file-2"></i>
            <span class="menu-text">{{ $t("MENU.INSTITUTES") }}</span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="
          permissionCan('partner_connections.setting.menu') &&
          selected_site_id > 0 &&
          moduleIsEnabled('PartnerConnections')
        "
        to="/settings/partnerConnections/partnerConnections"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-list-3"></i>
            <span class="menu-text">{{ $t("MENU.PARTNER_CONNECTIONS") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="permissionCan('module.career.menu') && moduleIsEnabled('Career')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/career'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-rocket"></i>
          <span class="menu-text">{{ $t("MENU.CAREER") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('career.career.menu') && selected_site_id > 0"
              to="/cms/career/career"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-notes"></i>
                  <span class="menu-text">{{ $t("MENU.POSITIONS") }}</span>
                </a>
              </li>
            </router-link>

            <!-- externalReferences Settings -->
            <router-link
              v-if="permissionCan('career.setting.menu')"
              to="/cms/career/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF external_references Settings -->
          </ul>
        </div>
      </li>

      <li
        v-if="
          permissionCan('module.external_references.menu') &&
          moduleIsEnabled('ExternalReferences')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/externalReferences'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-clip-symbol"></i>
          <span class="menu-text">{{ $t("MENU.EXTERNAL_REFERENCES") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('external_references.category.menu') &&
                selected_site_id > 0
              "
              to="/cms/externalReferences"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-clip-symbol"></i>
                  <span class="menu-text">{{
                    $t("MENU.EXTERNAL_REFERENCES")
                  }}</span>
                </a>
              </li>
            </router-link>

            <!-- externalReferences Settings -->
            <router-link
              v-if="permissionCan('external_references.setting.menu')"
              to="/cms/externalReferences/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF external_references Settings -->
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.faq.menu') && moduleIsEnabled('FAQ')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/faq'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-clip-symbol"></i>
          <span class="menu-text">{{ $t("MENU.FAQ") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('faq.category.menu') && selected_site_id > 0"
              to="/cms/faq"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-clip-symbol"></i>
                  <span class="menu-text">{{ $t("MENU.FAQ") }}</span>
                </a>
              </li>
            </router-link>

            <!-- FAQ Settings -->
            <router-link
              v-if="permissionCan('faq.setting.menu')"
              to="/cms/faq/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF FAQ Settings -->
          </ul>
        </div>
      </li>
      <li
        v-if="
          permissionCan('module.colleagues.menu') &&
          moduleIsEnabled('Colleagues')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/colleagues'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-user"></i>
          <span class="menu-text">{{
            $t("MENU.COLLEAGUES_ADMINISTRATION")
          }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('colleagues.colleague.menu') &&
                selected_site_id > 0
              "
              to="/cms/colleagues/colleagues"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-users"></i>
                  <span class="menu-text">{{ $t("MENU.COLLEAGUES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="
                permissionCan('colleagues.colleagueCategory.menu') &&
                selected_site_id > 0
              "
              to="/cms/colleagues/colleagueCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-layers"></i>
                  <span class="menu-text">{{
                    $t("MENU.COLLEAGUE_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('colleagues.setting.menu')"
              to="/cms/colleagues/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <!-- <pre>{{ moduleStatuses }}</pre> -->
      <li
        v-if="
          permissionCan('module.instructor.menu') &&
          moduleIsEnabled('Instructors')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/instructors'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-customer"></i>
          <span class="menu-text">{{ $t("MENU.INSTRUCTOR") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('instructors.instructor.menu') &&
                selected_site_id > 0
              "
              to="/cms/instructors"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-customer"></i>
                  <span class="menu-text">{{ $t("MENU.INSTRUCTOR") }}</span>
                </a>
              </li>
            </router-link>

            <!-- FAQ Settings -->
            <router-link
              v-if="permissionCan('instructors.setting.menu')"
              to="/cms/instructors/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF FAQ Settings -->
          </ul>
        </div>
      </li>
      <router-link
        v-if="
          permissionCan('popups.category.menu') &&
          selected_site_id > 0 &&
          moduleIsEnabled('Popups')
        "
        to="/cms/popups"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon-browser"></i>
            <span class="menu-text">{{ $t("MENU.POPUPS") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="permissionCan('module.gdpr.menu') && moduleIsEnabled('Gdpr')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cookieManagement'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-settings-1"></i>
          <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('gdpr.code.menu')"
              to="/cms/cookieManagement/cookies"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-list-2"></i>
                  <span class="menu-text">{{ $t("MENU.COOKIES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('gdpr.category.menu') && selected_site_id > 0"
              to="/cms/cookieManagement/cookieCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-indent-dots"></i>
                  <span class="menu-text">{{
                    $t("MENU.COOKIE_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="permissionCan('system.site.menu') && selected_site_id > 0"
              to="/cms/cookieManagement/settings"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-settings"></i>
                  <span class="menu-text">{{ $t("MENU.PAGE_SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="permissionCan('system.user.menu') && selected_site_id == 0"
        to="/settings/users"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-user-1"></i>
            <span class="menu-text">{{ $t("MENU.users") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.userGroup.menu') && selected_site_id == 0"
        to="/settings/userGroups"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-group"></i>
            <span class="menu-text">{{ $t("MENU.userGroups") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.role.menu') && selected_site_id == 0"
        to="/settings/roles"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-interface-8"></i>
            <span class="menu-text">{{ $t("MENU.roles") }}</span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="
          permissionCan('humanResources.employee.menu') && selected_site_id == 0
        "
        to="/humanResource/employees"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon menu-icon flaticon-users"></i>
            <span class="menu-text">{{ $t("MENU.EMPLOYEES") }}</span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="
          permissionCan('humanResources.holidayRequest.menu') &&
          selected_site_id == 0
        "
        to="/humanResource/holidayRequests"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon menu-icon flaticon2-calendar-5"></i>
            <span class="menu-text">{{ $t("MENU.holidayRequest") }}</span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="
          permissionCan('humanResources.holiday.menu') && selected_site_id == 0
        "
        to="/humanResource/holidays"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon menu-icon flaticon-list-2"></i>
            <span class="menu-text">Szabadságok</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="
          permissionCan('cafeteria.cafeteriaStatementData.menu') &&
          selected_site_id == 0
        "
        to="/humanResource/cafeteria/cafeteriaStatementDatas"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon menu-icon flaticon-list"></i>
            <span class="menu-text">{{
              $t("MENU.CAFETERIA_SATEMENT_DATA")
            }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="
          permissionCan('humanResources.year.menu') && selected_site_id == 0
        "
        to="/humanResource/redletterdays"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon menu-icon flaticon-event-calendar-symbol"></i>
            <span class="menu-text">{{ $t("MENU.RED_LETTER_DAYS") }}</span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="permissionCan('system.currency.menu') && selected_site_id == 0"
        to="/settings/currency"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-coins"></i>
            <span class="menu-text">{{ $t("MENU.currencies") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.country.menu') && selected_site_id == 0"
        to="/settings/country"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-earth-globe"></i>
            <span class="menu-text">{{ $t("MENU.countries") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.setting.update') && selected_site_id == 0"
        to="/settings/refreshCache"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-refresh"></i>
            <span class="menu-text">{{ $t("MENU.cache_refresh") }} </span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="permissionCan('filings.filing.menu') && selected_site_id == 0"
        to="/settings/filings/filingBooks"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-tool"></i>
            <span class="menu-text">{{ $t("MENU.filingBooks") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.activity.menu') && selected_site_id == 0"
        to="/settings/activities"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-list-2"></i>
            <span class="menu-text">{{ $t("MENU.ACTIVITIES") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="
          permissionCan('system.generalsetting.menu') && selected_site_id == 0
        "
        to="/settings/defaultSettings"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-settings-1"></i>
            <span class="menu-text">{{ $t("MENU.DEFAULT_SETTINGS") }}</span>
          </a>
        </li>
      </router-link>
    </ul>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import SiteService from "@/core/services/site.service.js";
import i18nService from "@/core/services/i18n.service.js";
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  // props: ["selected_site_id"],
  data() {
    return {
      permissions: [],
      // selected_site_id: -1,
      selected_site_id: SiteService.getActiveSiteId(),
      system_settings: {
        id: 0,
        name: this.$t("MENU.systemSettings"),
      },
      main_dashboard: {
        id: -1,
        name: this.$t("FORM_INPUT_NAMES.main_dashboard"),
      },
      loader: false,
      module_statuses: [],
    };
  },

  computed: {
    ...mapGetters(["filingBookCollection", "statuses", "currentUser"]),
    userSiteCollection() {
      let items = [];
      // return items;
      items = [...this.$store.getters.authUserSites];
      if (
        this.permissionCan("system.setting.menu") &&
        !items.find((item) => item.id == 0)
      ) {
        items.push(this.system_settings);
      }

      // if(!this.permissionCan("system.setting.menu") && this.selected_site_id > 0){
      if (this.selected_site_id > 0) {
        items.unshift(this.main_dashboard);
      }

      return items.map((item) => ({
        value: item.id,
        text: item.name,
        locales: item.locales,
      }));
    },
    moduleStatuses() {
      return this.$store.getters.module_statuses;
    },
  },

  watch: {
    currentUser(val) {
      if (val && val.sites && val.sites.length == 0) {
        this.$nextTick(() => {
          this.selected_site_id = 0;
        });
      }
    },
  },

  methods: {
    changeSite(val) {
      this.loader = true;
      SiteService.setActiveSiteId(val);
      if (val != -1) {
        i18nService.setSiteLocals(
          this.userSiteCollection.find((item) => item.value == val).locales
        );
      }

      window.location.href = window.location.origin;
    },

    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions);
        // this.permissions = JSON.parse(localStorage.permissions).filter(
        //   (item) => {
        //     return (
        //       item.name.indexOf("menu") > -1 || item.name.indexOf("modul") > -1
        //     );
        //   }
        // );
      }
    },

    moduleIsEnabled(module) {
      if (typeof this.moduleStatuses[module] !== "undefined") {
        return this.moduleStatuses[module];
      }
      return false;
    },
  },

  mounted() {
    this.setPermissions();

    this.$nextTick(() => {
      if (
        this.currentUser &&
        this.currentUser.sites &&
        this.currentUser.sites.length == 0
      ) {
        this.selected_site_id = 0;
      }
    });
  },
};
</script>
